import React, { useState } from 'react';
import press from '../../../assets/img/banner/news/press.svg';
import styled from 'styled-components';

const TabContainer = styled.div`
    position: absolute;
    bottom: clamp(10px, 2vw, 20px);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    background-color: rgba(255, 255, 255, 0.2);
    padding: clamp(3px, 1vw, 5px);
    border-radius: clamp(15px, 2vw, 20px);
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    width: fit-content;
    max-width: 90%;

    @media (max-width: 768px) {
        bottom: 10px;
    }

    @media (max-width: 480px) {
        bottom: 5px;
    }
`;

const TabButton = styled.button`
    padding: clamp(6px, 1.5vw, 8px) clamp(12px, 2vw, 16px);
    margin: clamp(1px, 0.5vw, 2px);
    cursor: pointer;
    border-radius: clamp(10px, 1.5vw, 15px);
    border: none;
    font-size: clamp(12px, 1.5vw, 14px);
    transition: all 0.3s ease;
    white-space: nowrap;
    background-color: ${props => props.isActive ? 'white' : 'transparent'};
    color: ${props => props.isActive ? '#333' : 'white'};

    @media (max-width: 768px) {
        padding: 6px 12px;
        font-size: 12px;
    }

    @media (max-width: 480px) {
        padding: 4px 8px;
        font-size: 11px;
    }
`;


const SiteBreadcrumb = (props) => {
	const { breadcrumbsClass, onViewModeChange } = props;
	const [viewMode, setViewMode] = useState('normal'); // 'normal' or 'compact'
	const handleViewModeChange = (mode) => {
		setViewMode(mode);
		if (onViewModeChange) {
			onViewModeChange(mode);
		}
	};

	return (
		<div className={breadcrumbsClass ? breadcrumbsClass : 'rs-breadcrumbs breadcrumbs-overlay'}>
            <div className="breadcrumbs-img">
                <img 
                    src={press} 
                    alt="Breadcrumbs Image" 
                    onContextMenu={(e) => e.preventDefault()} 
                />
                <TabContainer>
                    <TabButton
                        isActive={viewMode === 'normal'}
                        onClick={() => handleViewModeChange('normal')}
                    >
                        Normal
                    </TabButton>
                    <TabButton
                        isActive={viewMode === 'compact'}
                        onClick={() => handleViewModeChange('compact')}
                    >
                        Compact
                    </TabButton>
                </TabContainer>
            </div>
        </div>
	);
}

export default SiteBreadcrumb;