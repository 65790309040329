import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CourseMain from './CourseMain';

import api from '../../api/api';

// Image
import banner from '../../assets/img/banner/cail.png';
import logo from '../../assets/img/logo/csai.png';


const TutorialTopic = () => {
    let search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const [topic, setTopic] = React.useState({});

    React.useEffect(() => {
        api.get('/tutorial/topic?id=' + id)
        .then(res => {
            // console.log('tutorial', res.data);
            setTopic(res.data);
        })
        .catch(err => {
            console.log(err);
        })
    }, [id]);

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='tutorial'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle={topic?.name? topic.name.toUpperCase() : 'Tutorial'}
                // innerClass="breadcrumbs-text"
                pageName={topic?.name? topic.name.toUpperCase() : 'Tutorial'}
                breadcrumbsImg={banner}
            />
            {/* breadcrumb-area-start */}

            {/* Course Main */}
            <CourseMain topic={topic}/>
            {/* Course Main */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default TutorialTopic;