import React from 'react';
import { Helmet } from 'react-helmet';

import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer/Footer';

import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Image and Logos
import banner from '../../assets/img/banner/cail.png';
import logo from '../../assets/img/logo/csai.png';
import icon from '../../assets/img/logo/ai.png';

import ServiceDetailsContent from './ServiceDetailsContent';

const Solutions = () => {

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={icon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='solutions'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
            />
            <SiteBreadcrumb
                pageTitle="Solutions"
                titleClass="page-title"
                pageName="Solutions"
                breadcrumbsImg={banner}
            />
            <ServiceDetailsContent />
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
        </React.Fragment>

    );
}


export default Solutions;