// useNewsFetch.js
import { useState, useEffect, useCallback } from 'react';
import api_news from '../../api/api_news';

const useNewsFetch = (initialPage = 1) => {
    // Basic states
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [isSearchActive, setIsSearchActive] = useState(false);

    // Pagination state
    const [pagination, setPagination] = useState({
        total_pages: 0,
        has_next: false,
        has_prev: false
    });

    // Search metadata state
    const [searchMetadata, setSearchMetadata] = useState({
        applied_filters: {},
        search_time: 0,
        total_results: 0
    });

    // Regular news fetch
    const fetchArticles = useCallback(async (page = currentPage) => {
        setLoading(true);
        setIsSearchActive(false);
        setError(null);
        try {
            const response = await api_news.get(`/news?page=${page}`);
            
            if (response.data.status === 'success') {
                setArticles(response.data.data);
                setPagination({
                    total_pages: response.data.meta.total_pages,
                    has_next: response.data.meta.has_next,
                    has_prev: response.data.meta.has_prev
                });
                setCurrentPage(response.data.meta.page);
            } else {
                throw new Error('Failed to fetch articles');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [currentPage]);

    // Search articles
    const searchArticles = useCallback(async (searchParams) => {
        setLoading(true);
        setIsSearchActive(true);
        setError(null);
        try {
            const response = await api_news.post('/search', {
                ...searchParams,
                page: searchParams.page || 1,
                per_page: searchParams.per_page || 10
            });

            if (response.data) {
                setArticles(response.data.results);
                setSearchMetadata(response.data.metadata);
                setPagination({
                    total_pages: response.data.metadata.pagination.total_pages,
                    has_next: response.data.metadata.pagination.current_page < response.data.metadata.pagination.total_pages,
                    has_prev: response.data.metadata.pagination.current_page > 1
                });
                setCurrentPage(response.data.metadata.pagination.current_page);
            } else {
                throw new Error('Search failed');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    // Initial fetch
    useEffect(() => {
        fetchArticles();
    }, []);

    return {
        articles,
        loading,
        error,
        currentPage,
        pagination,
        searchMetadata,
        isSearchActive,
        fetchArticles,
        searchArticles
    };
};

export default useNewsFetch;