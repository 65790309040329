// NewsLoader.jsx
import React from 'react';

const NewsLoader = () => {
    return (
        <div className="news-loader">
            <div className="loader-container">
                <div className="loader-spinner"></div>
                <div className="loader-text">
                    <span>PressWiz</span>
                    <span>Loading</span>
                </div>
            </div>

            <style jsx>{`
                .news-loader {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    background: linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                    overflow: hidden;
                }

                .loader-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1rem;
                }

                .loader-spinner {
                    width: 80px;
                    height: 80px;
                    border: 8px solid #308C84;
                    border-top: 8px solid transparent;
                    border-radius: 50%;
                    animation: spin 1.5s linear infinite;
                }

                .loader-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .loader-text span:first-child {
                    font-size: 2rem;
                    color: #308C84;
                    font-weight: bold;
                }

                .loader-text span:last-child {
                    font-size: 1rem;
                    color: #6c757d;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
        </div>
    );
};

export default NewsLoader;