import React, { useState, useEffect, useMemo } from 'react';
import { formatBanglaDate } from './dateUtils';
import ArticleSummaryModal from './ArticalSummaryModal';
import './ArticleCard.css';
import PLACEHOLDER_IMAGE from '../../assets/img/banner/news/dp.png';

// Placeholder image as base64 or imported
// const PLACEHOLDER_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj48cmVjdCB3aWR0aD0iMjUwIiBoZWlnaHQ9IjI1MCIgZmlsbD0iI2YwZjBmMCIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmaWxsPSIjY2NjIiBkeT0iLjM2ZW0iIHRleHQtYW5jaG9yPSJtaWRkbGUiPk5vIEltYWdlPC90ZXh0Pjwvc3ZnPg==';


const ArticleCard = ({
    article,
    index,
    source_name_map
}) => {
    // State for summary expansion and image loading
    const [isExpanded, setIsExpanded] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const MAX_SUMMARY_LENGTH = 250;

    // Memoized analysis to prevent unnecessary recalculations
    const summaryAnalysis = useMemo(() => {
        const analyzeSummary = (summary) => {
            const wordCount = summary.split(' ').length;
            const readingTime = Math.ceil(wordCount / 200); // Avg reading speed

            return {
                wordCount,
                readingTime,
                complexity: wordCount > 100 ? 'জটিল' : 'সহজ'
            };
        };

        return analyzeSummary(article['summary'] || 'কোনো সারাংশ পাওয়া যায়নি');
    }, [article['summary']]);

    // Image Error Handling with Improved Logic
    useEffect(() => {
        const handleImageLoading = () => {
            if (article['image_url']) {
                setImageUrl(article['image_url']);
            } else {
                setImageUrl(PLACEHOLDER_IMAGE);
            }
        };

        handleImageLoading();
    }, [article['image_url']]);

    // Advanced Summary Highlighting Function
    const highlightSummary = (summary) => {
        const keywords = [
            { word: 'রাজনৈতিক', color: '#FF6B6B', weight: 'bold' },
            { word: 'নাগরিক', color: '#4ECDC4', weight: 'bold' },
            { word: 'গণঅভ্যুত্থান', color: '#45B7D1', weight: 'bold' },
            { word: 'তরুণ', color: '#FFA07A', weight: 'bold' }
        ];

        let highlightedSummary = summary;

        keywords.forEach(({ word, color, weight }) => {
            const regex = new RegExp(`(${word})`, 'gi');
            highlightedSummary = highlightedSummary.replace(
                regex,
                `<span class="keyword-highlight" style="
                    background-color: ${color}; 
                    color: white; 
                    font-weight: ${weight};
                    padding: 0.1rem 0.3rem;
                    border-radius: 4px;
                    margin: 0 0.2rem;
                ">$1</span>`
            );
        });

        return highlightedSummary;
    };

    // Truncate summary with improved logic
    const getTruncatedSummary = () => {
        if (!article['summary']) return 'কোনো সারাংশ পাওয়া যায়নি';

        if (article['summary'].length <= MAX_SUMMARY_LENGTH || isExpanded) {
            return article['summary'];
        }
        return `${article['summary'].slice(0, MAX_SUMMARY_LENGTH)}...`;
    };

    // Render Categories with Tooltip
    const renderCategories = () => {
        if (!article['categories']) return null;

        return (
            <div className="category-badges">
                {article['categories'].slice(0, 3).map((category, idx) => (
                    <span
                        key={idx}
                        className="category-badge"
                        title={category}
                    >
                        {category}
                    </span>
                ))}
                {article['categories'].length > 3 && (
                    <span
                        className="category-badge more-categories"
                        title={article['categories'].slice(3).join(', ')}
                    >
                        +{article['categories'].length - 3}
                    </span>
                )}
            </div>
        );
    };

    return (
        <div
            className="enhanced-article-card"
            style={{ '--index': index }}
        >

            {/* Image Section with Overlay */}
            <div className="article-image-container">
                <img
                    src={imageUrl || PLACEHOLDER_IMAGE}
                    alt={article['title'] || 'Article Image'}
                    className="article-image"
                    onError={(e) => {
                        e.target.src = PLACEHOLDER_IMAGE;
                    }}
                />
                {/* <div className="image-overlay">
                    <div className="overlay-content">
                        {renderCategories()}
                    </div>
                </div> */}
            </div>

            {/* Card Content */}
            <div className="card-content">
                {/* Card Header */}
                <div className="card-header">
                    {/* Article Title */}
                    <h2 className="article-title">
                        {article['title'] || 'শিরোনাম পাওয়া যায়নি'}
                    </h2>
                </div>

                {/* Summary Section */}
                <div className="summary-section">
                    {/* Summary Text */}
                    <p
                        dangerouslySetInnerHTML={{
                            __html: highlightSummary(
                                getTruncatedSummary()
                            )
                        }}
                    />

                    {/* Expand/Collapse and Categories Container */}
                    <div className="summary-actions">
                        {/* Expand Button */}
                        {article['summary'] && article['summary'].length > MAX_SUMMARY_LENGTH && (
                            <button
                                className="expand-summary"
                                onClick={() => setIsModalOpen(true)}
                            >
                                সম্পূর্ণ পড়ুন
                            </button>
                        )}

                        {/* Categories */}
                        {renderCategories()}
                    </div>

                    {/* Summary Statistics */}
                    {/* <div className="summary-stats">
                        <div className="stat-item">
                            <span className="stat-label">শব্দ</span>
                            <span className="stat-value">
                                {summaryAnalysis.wordCount}
                            </span>
                        </div>
                        <div className="stat-item">
                            <span className="stat-label">পঠন সময়</span>
                            <span className="stat-value">
                                {summaryAnalysis.readingTime} মিনিট
                            </span>
                        </div>
                    </div> */}
                </div>

                {/* Card Footer */}
                <div className="card-footer">
                    {/* Article Metadata */}
                    <div className="article-meta">
                        {/* Author and Source Details */}
                        <div className="meta-details">
                            <span className="author">
                                <i className="fas fa-user-edit"></i>
                                {article.author || 'অজ্ঞাত লেখক'}
                            </span>
                            ||
                            <span className="source">
                                <i className="fas fa-newspaper"></i>
                                {source_name_map[article.source_name] || 'অজ্ঞাত সংবাদ'}
                            </span>
                            ||
                            {/* Publication Date */}
                            <span className="publication-date">
                                <i className="fas fa-calendar-alt"></i>
                                {article.publication_date
                                    ? formatBanglaDate(article.publication_date)
                                    : 'তারিখ পাওয়া যায়নি'}
                            </span>
                        </div>
                        {/* Read More Link */}
                        {article.source_url && (
                            <a
                                href={article.source_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="read-more"
                            >
                                আরও পড়ুন
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                                    />
                                </svg>
                            </a>
                        )}

                    </div>

                </div>
            </div>
            {/* Summary Modal */}
            <ArticleSummaryModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                article={article}
                source_name_map={source_name_map}
                highlightSummary={highlightSummary}
            />
        </div>
    );
};

export default ArticleCard;