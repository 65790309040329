import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import './NewspaperComponent.css';
import useNewsFetch from './useNewsFetch';
import NewsLoader from './NewsLoader';
import ArticleCard from './ArticleCard';
import api_news from '../../api/api_news';

const EnhancedNewspaperComponent = () => {
    // Custom Hook
    const {
        articles,
        loading: initialLoading,
        error,
        currentPage,
        pagination,
        searchMetadata,
        isSearchActive,
        fetchArticles,
        searchArticles
    } = useNewsFetch();

    // Refs
    const refs = {
        articlesContainer: useRef(null),
        top: useRef(null),
        searchDebounce: useRef(null),
        observer: useRef(null)
    };

    const formatDate = (date) => {
        // Create a date object in local time
        const localDate = new Date(date);

        // Get local ISO string components
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');

        // Format as YYYY-MM-DDTHH:mm
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    // State Management
    const [state, setState] = useState({
        searchQuery: '',
        // get local time
        start_date: formatDate(new Date(Date.now() - 24 * 60 * 60 * 1000)),
        end_date: formatDate(new Date()),
        selectedCategory: null,
        isLoading: false,
        transitionKey: 0,
        inputPage: currentPage,
        showSearchButton: true,
        trendingTopics: [],
        lastScrollPosition: 0,
        searchParameters: {  // Add this
            query: '',
            category: null,
            isActive: false
        }
    });

    // Constants
    const CONSTANTS = useMemo(() => ({
        SOURCE_NAME_MAP: {
            'prothom_alo': 'প্রথম আলো',
            'samakal': 'সমকাল',
        },
        CATEGORIES: [
            { id: 'রাজনীতি', name: 'রাজনীতি' },
            { id: 'অর্থনীতি', name: 'অর্থনীতি' },
            // { id: 'আন্তর্জাতি ', name: 'আন্তর্জাতিক' },
            { id: 'প্রযুক্তি', name: 'প্রযুক্তি' }
        ],
        DEBOUNCE_DELAY: 300
    }), []);

    // Memoized Handlers
    const handlers = useMemo(() => ({
        updateState: (updates) => {
            setState(prev => ({ ...prev, ...updates }));
        },

        incrementTransitionKey: () => {
            setState(prev => ({ ...prev, transitionKey: prev.transitionKey + 1 }));
        }
    }), []);

    // Fetch Trending Topics
    const fetchTrendingTopics = useCallback(async () => {
        try {
            const response = await api_news.get('/trending');
            handlers.updateState({ trendingTopics: response.data['trending_topics'] });
        } catch (error) {
            console.error('Error fetching trending topics:', error);
        }
    }, [handlers]);


    const handleSearchInput = useCallback((value) => {
        handlers.updateState({
            searchQuery: value,
            showSearchButton: value.trim().length > 0
        });
    }, [handlers]);

    const handleDateChange = useCallback((date, type) => {
        handlers.updateState({
            [type]: date
        });
    }, [handlers]);

    const handleSearchSubmit = useCallback(() => {
        if (true) {
            const searchParams = {
                query: state.searchQuery,
                start_date: state.start_date,
                end_date: state.end_date,
                category: state.selectedCategory,
                page: 1
            };
            // console.log('handleSearchSubmit', searchParams);
            handlers.updateState({
                searchParameters: {
                    query: state.searchQuery,
                    start_date: state.start_date,
                    end_date: state.end_date,
                    category: state.selectedCategory,
                    isActive: true
                }
            });

            searchArticles(searchParams);
            handlers.incrementTransitionKey();
            handlers.updateState({ inputPage: 1 });
        }
    }, [state.searchQuery, state.start_date, state.end_date, state.selectedCategory, searchArticles, handlers]);

    // Category Selection
    const handleCategorySelect = useCallback((categoryId) => {
        const newCategory = state.selectedCategory === categoryId ? null : categoryId;

        handlers.updateState({
            selectedCategory: newCategory,
            searchParameters: {
                ...state.searchParameters,
                category: newCategory
            }
        });

        if (state.searchQuery.trim() || newCategory) {
            const searchParams = {
                query: state.searchQuery,
                category: newCategory,
                page: 1
            };
            searchArticles(searchParams);
        }
        handlers.incrementTransitionKey();
        handlers.updateState({ inputPage: 1 });
    }, [state.searchQuery, state.selectedCategory, searchArticles, handlers]);

    // Clear Search
    const handleClearSearch = useCallback(() => {
        handlers.updateState({
            searchQuery: '',
            start_date: formatDate(new Date(Date.now() - 24 * 60 * 60 * 1000)),
            end_date: formatDate(new Date()),
            selectedCategory: null,
            inputPage: 1,
            searchParameters: {
                query: '',
                start_date: '',
                end_date: '',
                category: null,
                isActive: false
            }
        });
        fetchArticles(1);
        handlers.incrementTransitionKey();
    }, [fetchArticles, handlers]);

    // Pagination Handlers
    const handlePageChange = useCallback(async (newPage) => {
        if (newPage < 1 || newPage > pagination.total_pages) return;

        handlers.updateState({ isLoading: true });
        try {
            if (state.searchParameters.isActive) {
                await searchArticles({
                    query: state.searchParameters.query,
                    start_date: state.start_date,
                    end_date: state.end_date,
                    category: state.searchParameters.category,
                    page: newPage,
                    preserve_search: true
                });
            } else {
                await fetchArticles(newPage);
            }
            handlers.updateState({ inputPage: newPage });
            refs.top.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            console.error('Page change error:', error);
        } finally {
            handlers.updateState({ isLoading: false });
        }
    }, [
        state.start_date,
        state.end_date,
        fetchArticles,
        searchArticles,
        pagination.total_pages,
        state.searchParameters,
        handlers
    ]);

    // Direct Page Input Handler
    const handleDirectPageChange = useCallback(() => {
        const pageNum = Number(state.inputPage);
        if (pageNum > 0 && pageNum <= pagination.total_pages) {
            handlePageChange(pageNum);
        } else {
            alert(`Please enter a valid page number between 1 and ${pagination.total_pages}`);
            handlers.updateState({ inputPage: currentPage });
        }
    }, [state.inputPage, pagination.total_pages, currentPage, handlePageChange, handlers]);


    const handleTrendingTopicClick = useCallback((topic) => {
        const searchParams = {
            query: topic,
            category: state.selectedCategory,
            page: 1
        };

        handlers.updateState({
            searchQuery: topic,
            inputPage: 1,
            searchParameters: {
                query: topic,
                category: state.selectedCategory,
                isActive: true
            }
        });

        searchArticles(searchParams);
        handlers.incrementTransitionKey();
    }, [state.selectedCategory, searchArticles, handlers]);

    // Infinite Scroll Setup
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && pagination.has_next && !state.isLoading) {
                handlePageChange(currentPage + 1);
            }
        }, options);

        if (refs.observer.current) {
            observer.observe(refs.observer.current);
        }

        return () => observer.disconnect();
    }, [currentPage, pagination.has_next, state.isLoading, handlePageChange]);

    // Initial Setup
    useEffect(() => {
        fetchTrendingTopics();
        return () => {
            if (refs.searchDebounce.current) {
                clearTimeout(refs.searchDebounce.current);
            }
        };
    }, [fetchTrendingTopics]);

    // Error Handling
    if (initialLoading && !state.isLoading) return <NewsLoader />;
    if (error != null) return <ErrorDisplay error={error} onRetry={() => isSearchActive ? handleSearchSubmit() : fetchArticles()} />;

    return (
        <div className="enhanced-newspaper-container">
            <Header
                refs={refs}
                state={state}
                handlers={handlers}
                CONSTANTS={CONSTANTS}
                searchMetadata={searchMetadata}
                isSearchActive={state.searchParameters.isActive}
                onSearchSubmit={handleSearchSubmit}
                onSearchInput={handleSearchInput}
                onCategorySelect={handleCategorySelect}
                onClearSearch={handleClearSearch}
                onDateChange={handleDateChange}
            />

            <TrendingTopics
                topics={state.trendingTopics}
                onRefresh={fetchTrendingTopics}
                onTopicSelect={handleTrendingTopicClick}
            />

            <ArticlesSection
                articles={articles}
                transitionKey={state.transitionKey}
                isLoading={state.isLoading}
                sourceNameMap={CONSTANTS.SOURCE_NAME_MAP}
                refs={refs}
                onClearSearch={handleClearSearch}
            />

            <Pagination
                currentPage={currentPage}
                pagination={pagination}
                inputPage={state.inputPage}
                isLoading={state.isLoading}
                onPageChange={handlePageChange}
                onInputChange={(value) => handlers.updateState({ inputPage: value })}
                onDirectPageChange={handleDirectPageChange}
            />

            {state.isLoading && <LoadingOverlay />}
        </div>
    );
};

const Header = ({
    refs,
    state,
    isSearchActive,
    searchMetadata,
    CONSTANTS,
    onSearchSubmit,
    onSearchInput,
    onClearSearch,
    onCategorySelect,
    onDateChange
}) => (
    <header className="enhanced-news-header" ref={refs.top}>
        <div className="header-content">
            <h1 className="page-title">
                {isSearchActive ? 'অনুসন্ধানের ফলাফল' : 'সাম্প্রতিক সংবাদ'}
            </h1>

            {/* {isSearchActive && searchMetadata && (
                <div className="search-stats">
                    <span>মোট ফলাফল: {searchMetadata.total_results}</span>
                    <span>সময়: {searchMetadata.search_time.toFixed(3)}s</span>
                </div>
            )} */}

            <div className="search-filter-container">
                <div className="search-input-wrapper">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="সংবাদ অনুসন্ধান করুন..."
                        value={state.searchQuery}
                        onChange={(e) => onSearchInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onSearchSubmit();
                            }
                        }}
                    />

                    {state.showSearchButton && (
                        <button
                            className="pagination-btn"
                            onClick={onSearchSubmit}
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <i class="fa fa-search" style={{ "font-size": 24 }}></i>
                        </button>
                    )}
                    {(
                        <button
                            className="pagination-btn"
                            onClick={onClearSearch}
                            style={{
                                marginLeft: '10px',
                                backgroundColor: 'red',
                                color: 'white'
                            }}
                        >
                            <i class="fa fa-refresh"></i>
                        </button>
                    )}
                    {/* <i className="search-icon fas fa-search"></i> */}
                </div>
                {/**Date filter */}
                <div className="date-filter">
                    <label style={{ fontWeight: 800 }}>তারিখ:{' '} </label>
                    <input
                        type="datetime-local"
                        className="date-input"
                        value={state.start_date}
                        onChange={(e) => onDateChange(e.target.value, 'start_date')}
                    />
                    <span style={{ fontWeight: 800 }}> থেকে </span>
                    <input
                        type="datetime-local"
                        className="date-input"
                        value={state.end_date}
                        onChange={(e) => onDateChange(e.target.value, 'end_date')}
                    />
                </div>
                <div className="category-filters">
                    {CONSTANTS.CATEGORIES.map(category => (
                        <button
                            key={category.name}
                            className={`category-btn ${state.selectedCategory === category.name ? 'active' : ''}`}
                            onClick={() => onCategorySelect(category.name)}
                        >
                            {category.name}
                        </button>
                    ))}
                    {/**CLEAR BUTTON*/}
                    <button
                        className={`category-btn ${state.selectedCategory === null ? 'active' : ''}`}
                        onClick={() => {
                            onCategorySelect(null);
                            onClearSearch();
                        }}
                    >
                        সব ক্যাটাগরি
                    </button>
                </div>
            </div>
        </div>
    </header>
);

const TrendingTopics = ({
    topics,
    onRefresh,
    onTopicSelect
}) => {
    const [visibleTopics, setVisibleTopics] = useState(0); // Show first 5 topics when collapsed

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const [isExpanded, setIsExpanded] = useState(() => {
        const saved = localStorage.getItem('trendingTopicsExpanded');
        return saved !== null ? JSON.parse(saved) : false;
    });

    useEffect(() => {
        localStorage.setItem('trendingTopicsExpanded', JSON.stringify(isExpanded));
    }, [isExpanded]);

    return (
        <div className="trending-topics-section">
            <div className="trending-header">
                <div className="header-left">
                    <h2>ট্রেন্ডিং টপিক</h2>
                    <button
                        onClick={toggleExpand}
                        className="toggle-button"
                        aria-label={isExpanded ? 'Collapse' : 'Expand'}
                    >
                        <i className={`fa fa-chevron-${isExpanded ? 'up' : 'down'}`} />
                    </button>
                </div>
                <div className="trending-refresh">
                    <button onClick={onRefresh}>
                        <i className="fas fa-sync-alt"></i>
                    </button>
                </div>
            </div>
            <div className={`trending-topics-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
                {(isExpanded ? topics : topics.slice(0, visibleTopics)).map((topic, index) => (
                    <div key={index} className="trending-topic-card">
                        <div className="topic-rank">{index + 1}</div>
                        <div className="topic-content">
                            <h3
                                className="topic-title"
                                onClick={() => onTopicSelect(topic.topic)}
                            >
                                {topic.topic}
                            </h3>
                            <div className="topic-stats">
                                <span className="article-count">
                                    <i className="fas fa-newspaper"></i>
                                    {topic.article_count} টি সংবাদ
                                </span>
                                <span className="trend-score">
                                    <i className="fas fa-chart-line"></i>
                                    {parseFloat(topic.final_trend_score).toFixed(1)}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
                {!isExpanded && topics.length > visibleTopics && (
                    <div className="show-more">
                        <button onClick={toggleExpand}>
                            আরও দেখুন
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const ArticlesSection = ({
    articles,
    transitionKey,
    isLoading,
    sourceNameMap,
    refs,
    onClearSearch
}) => (
    <CSSTransition
        key={transitionKey}
        classNames="articles-transition"
        timeout={500}
    >
        <div
            ref={refs.articlesContainer}
            className={`single-column-articles-container ${isLoading ? 'loading' : ''}`}
        >
            {articles.length > 0 ? (
                articles.map((article, index) => (
                    <div
                        key={article.id}
                        className="single-article-row"
                    >
                        <ArticleCard
                            article={article}
                            index={index}
                            source_name_map={sourceNameMap}
                        />
                    </div>
                ))
            ) : (
                <div className="no-results">
                    <i className="fas fa-search-minus"></i>
                    <h3>কোনো সংবাদ পাওয়া যায়নি</h3>
                    <p>আপনার অনুসন্ধান বা ফিল্টার পরিবর্তন করুন</p>
                    <button onClick={onClearSearch}>
                        রিসেট করুন
                    </button>
                </div>
            )}
        </div>
    </CSSTransition>
);

const Pagination = ({
    currentPage,
    pagination,
    inputPage,
    isLoading,
    onPageChange,
    onInputChange,
    onDirectPageChange
}) => (
    <div className="pagination">
        <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={!pagination.has_prev || isLoading}
            className={`pagination-btn ${isLoading ? 'loading' : ''}`}
        >
            {isLoading ? 'Loading...' : '← আগের পাতা'}
        </button>

        <div className="page-input-container">
            <input
                type="number"
                className="page-input"
                value={inputPage}
                onChange={(e) => onInputChange(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onDirectPageChange();
                    }
                }}
                min="1"
                max={pagination.total_pages}
            />
            <button
                className="go-to-page-btn"
                onClick={onDirectPageChange}
                disabled={isLoading}
            >
                যান
            </button>
        </div>

        <span className="page-info">
            / {pagination.total_pages}
        </span>

        <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={!pagination.has_next || isLoading}
            className={`pagination-btn ${isLoading ? 'loading' : ''}`}
        >
            {isLoading ? 'Loading...' : 'পরের পাতা →'}
        </button>
    </div>
);

const LoadingOverlay = () => (
    <div className="page-loading-overlay" />
);

const ErrorDisplay = ({ error, onRetry }) => (
    <div className="error-container">
        <h2>Error Loading News</h2>
        <p>{error}</p>
        <button onClick={onRetry}>
            Retry
        </button>
    </div>
);


export default EnhancedNewspaperComponent;