import React, { useState, useRef, useCallback, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import './CompactNewspaperComponent.css';
import useNewsFetch from './useNewsFetch';
import NewsLoader from './NewsLoader';
import Pagination from './Pagination';

const CompactNewspaperComponent = () => {
    const {
        articles,
        loading: initialLoading,
        error,
        currentPage,
        pagination,
        fetchArticles,
        searchArticles
    } = useNewsFetch();

    const refs = {
        articlesContainer: useRef(null),
        top: useRef(null),
    };

    // Enhanced state with timeRange
    const [state, setState] = useState({
        searchQuery: '',
        transitionKey: 0,
        inputPage: currentPage,
        timeRange: 'all',
        isLoading: false // Added loading state
    });

    // Time range options
    const timeRangeOptions = [
        { value: 'all', label: 'All Time' },
        { value: '1d', label: 'Last 24 Hours' },
        { value: '2d', label: 'Last 2 Days' },
        { value: '7d', label: 'Last 7 Days' },
        { value: '30d', label: 'Last 30 Days' }
    ];

    const handlers = useMemo(() => ({
        updateState: (updates) => {
            setState(prev => ({ ...prev, ...updates }));
        },
        incrementTransitionKey: () => {
            setState(prev => ({ ...prev, transitionKey: prev.transitionKey + 1 }));
        }
    }), []);

    const formatDate = (date) => {
        // Create a date object in local time
        const localDate = new Date(date);

        // Get local ISO string components
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');

        // Format as YYYY-MM-DDTHH:mm
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const getDateRange = useCallback((range) => {
        const end = formatDate(new Date());
        var start = new Date();

        switch (range) {
            case '1d':
                start = formatDate(new Date(Date.now() - 24 * 60 * 60 * 1000));
                break;
            case '2d':
                start = formatDate(new Date(Date.now() - 2 * 24 * 60 * 60 * 1000));
                break;
            case '7d':
                start = formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
                break;
            case '30d':
                start = formatDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
                break;
            default:
                return null;
        }
        return { start, end };
    }, []);

    const handleTimeRangeChange = useCallback((range) => {
        handlers.updateState({ timeRange: range });
        if (range !== 'all') {
            handleSearchSubmit(range);
        }else{
            fetchArticles(1);
        }
    }, []);

    const handleSearchSubmit = useCallback((timeRange = state.timeRange) => {
        if (state.searchQuery.trim() || timeRange !== 'all') {
            const dateRange = getDateRange(timeRange);
            // console.log('range', dateRange);
            const searchParams = {
                query: state.searchQuery,
                page: 1,
                start_date: dateRange?.start,
                end_date: dateRange?.end
            };

            handlers.updateState({ isLoading: true });
            searchArticles(searchParams).finally(() => {
                handlers.updateState({ isLoading: false });
            });
            handlers.incrementTransitionKey();
            handlers.updateState({ inputPage: 1 });
        }
    }, [state.searchQuery, state.timeRange, searchArticles, handlers, getDateRange]);

    const handlePageChange = useCallback(async (newPage) => {
        if (newPage < 1 || newPage > pagination.total_pages) return;

        handlers.updateState({ isLoading: true });
        try {
            if (state.timeRange !== 'all' || state.searchQuery.trim()) {
                const dateRange = getDateRange(state.timeRange);
                // console.log('dateRange', dateRange);
                await searchArticles({
                    query: state.searchQuery,
                    page: newPage,
                    start_date: dateRange?.start,
                    end_date: dateRange?.end
                });
            } else {
                await fetchArticles(newPage);
            }
            handlers.updateState({ inputPage: newPage });
            refs.top.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            console.error('Page change error:', error);
        } finally {
            handlers.updateState({ isLoading: false });
        }
    }, [
        pagination.total_pages,
        state.timeRange,
        state.searchQuery,
        fetchArticles,
        searchArticles,
        handlers,
        getDateRange
    ]);

    const handleDirectPageChange = useCallback(() => {
        const pageNum = Number(state.inputPage);
        if (pageNum > 0 && pageNum <= pagination.total_pages) {
            handlePageChange(pageNum);
        } else {
            alert(`Please enter a valid page number between 1 and ${pagination.total_pages}`);
            handlers.updateState({ inputPage: currentPage });
        }
    }, [state.inputPage, pagination.total_pages, currentPage, handlePageChange, handlers]);

    if (initialLoading) return <NewsLoader />;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="compact-newspaper-container" ref={refs.top}>
            <div className="compact-search">
                <div className="search-input-group">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={state.searchQuery}
                        onChange={(e) => handlers.updateState({ searchQuery: e.target.value })}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearchSubmit()}
                    />
                    <button onClick={() => handleSearchSubmit()}>Search</button>
                </div>
                <div className="time-range-filters">
                    {timeRangeOptions.map(option => (
                        <button
                            key={option.value}
                            className={`time-range-btn ${state.timeRange === option.value ? 'active' : ''}`}
                            onClick={() => handleTimeRangeChange(option.value)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            </div>

            <CSSTransition
                key={state.transitionKey}
                classNames="articles-transition"
                timeout={500}
            >
                <div className="compact-articles-container">
                    {articles.map((article) => (
                        <div key={article.id} className="compact-article-card">
                            <h3>{article.title}</h3>
                            <p>{article.summary}</p>
                            <div className="article-metadata">
                                <span>{new Date(article.publication_date).toLocaleDateString()}</span>
                                <a href={article.source_url} target="_blank" rel="noopener noreferrer">
                                    Read More
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </CSSTransition>

            <Pagination
                currentPage={currentPage}
                pagination={pagination}
                inputPage={state.inputPage}
                isLoading={state.isLoading}
                onPageChange={handlePageChange}
                onInputChange={(value) => handlers.updateState({ inputPage: value })}
                onDirectPageChange={handleDirectPageChange}
            />
        </div>
    );
};

export default CompactNewspaperComponent;