import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { formatBanglaDate } from './dateUtils';

import './Modal.css';

const Modal = ({ 
    isOpen, 
    onClose, 
    children, 
    title, 
    className = '',
    size = 'medium' 
}) => {
    // Prevent scrolling when modal is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        // Cleanup
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    // Handle escape key to close modal
    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, onClose]);

    // If modal is not open, return null
    if (!isOpen) return null;

    // Render modal using portal for better DOM placement
    return ReactDOM.createPortal(
        <div className="modal-overlay">
            <div 
                className={`modal-container ${className} modal-${size}`}
                role="dialog"
                aria-modal="true"
            >
                {/* Modal Header */}
                <div className="modal-header">
                    <h2 className="modal-title">{title}</h2>
                    <button 
                        className="modal-close-button" 
                        onClick={onClose}
                        aria-label="Close modal"
                    >
                        &times;
                    </button>
                </div>

                {/* Modal Content */}
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
};

// Article Summary Modal Specific Component
export const ArticleSummaryModal = ({ 
    isOpen, 
    onClose, 
    article, 
    source_name_map, 
    highlightSummary 
}) => {
    return (
        <Modal 
            isOpen={isOpen} 
            onClose={onClose} 
            title={article.title}
            size="large"
            className="article-summary-modal"
        >
            {/* Modal Metadata */}
            <div className="article-modal-meta">
                <div className="meta-item">
                    <i className="fas fa-user-edit"></i>
                    <span>{article.author || 'অজ্ঞাত লেখক'}</span>
                </div>
                <div className="meta-item">
                    <i className="fas fa-newspaper"></i>
                    <span>
                        {source_name_map[article.source_name] || 'অজ্ঞাত সংবাদ'}
                    </span>
                </div>
                <div className="meta-item">
                    <i className="fas fa-calendar-alt"></i>
                    <span>
                        {article.publication_date
                            ? formatBanglaDate(article.publication_date)
                            : 'তারিখ পাওয়া যায়নি'}
                    </span>
                </div>
            </div>

            {/* Summary Content */}
            <div 
                className="article-modal-summary"
                dangerouslySetInnerHTML={{
                    __html: highlightSummary(article.summary)
                }}
            />

            {/* Footer with Full Article Link */}
            {article.source_url && (
                <div className="article-modal-footer">
                    <a
                        href={article.source_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="full-article-link"
                    >
                        পুরো নিবন্ধ পড়ুন
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                            />
                        </svg>
                    </a>
                </div>
            )}
        </Modal>
    );
};

export default ArticleSummaryModal;