import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../components/Common/ScrollTop';
import CSAI_CODE_COVER from '../../assets/img/banner/code_cover.svg';
import COLAS_COVER from '../../assets/img/banner/colas.svg';
// import NEWS_SUMMARY_COVER from '../../assets/img/banner/news.jpg';
import Press from '../../assets/img/banner/news/press.svg';


const ServiceDetailsContent = () => {
    // State for scroll progress
    const [scrollProgress, setScrollProgress] = useState(0);

    // Refs
    const heroImageRef = useRef(null);
    const colasImageRef = useRef(null);
    const newsSummaryImageRef = useRef(null);
    const newsSummaryFeaturesRef = useRef([]);
    const newsSummaryCardsRef = useRef([]);

    // Helper function to add to refs
    const addToRefs = (el, refArray) => {
        if (el && !refArray.current.includes(el)) {
            refArray.current.push(el);
        }
    };

    // Render feature item helper function
    const renderFeatureItem = (title, description, icon, index, refArray) => (
        <div
            key={title}
            ref={(el) => addToRefs(el, refArray)}
            className={`feature-item fade-in delay-${(index + 1) * 2}`}
        >
            <div className="feature-icon">
                <i className={`fas ${icon}`}></i>
            </div>
            <div className="feature-content">
                <h5>{title}</h5>
                <p>{description}</p>
            </div>
        </div>
    );

    // Effect for scroll progress
    useEffect(() => {
        const handleScroll = () => {
            const totalScroll = document.documentElement.scrollTop;
            const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scroll = `${(totalScroll / windowHeight) * 100}%`;

            setScrollProgress(scroll);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Effect for intersection observer
    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate-in');
                }

                // Handle feature items delay
                if (newsSummaryFeaturesRef.current.includes(entry.target)) {
                    const index = newsSummaryFeaturesRef.current.indexOf(entry.target);
                    entry.target.style.transitionDelay = `${index * 0.2}s`;
                }

                // Handle cards animation delay
                if (newsSummaryCardsRef.current.includes(entry.target)) {
                    const index = newsSummaryCardsRef.current.indexOf(entry.target);
                    entry.target.style.animationDelay = `${index * 2}s`;
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        // Observe all animated elements
        const animatedElements = document.querySelectorAll(
            '.fade-in, .slide-up, .slide-in-right, .slide-in-left, .feature-item, .floating-cards .card'
        );
        animatedElements.forEach(el => observer.observe(el));

        return () => observer.disconnect();
    }, []);

    // Effect for parallax scrolling
    useEffect(() => {
        const handleParallax = () => {
            const scrolled = window.pageYOffset;
            const parallaxElements = document.querySelectorAll('.hero-image, .colas-image');

            parallaxElements.forEach(element => {
                const limit = element.offsetTop + element.offsetHeight;
                if (scrolled > element.offsetTop && scrolled <= limit) {
                    element.style.transform = `translateY(${(scrolled - element.offsetTop) * 0.1}px)`;
                }
            });
        };

        window.addEventListener('scroll', handleParallax);
        return () => window.removeEventListener('scroll', handleParallax);
    }, []);

    // Rest of the component remains the same as in your previous submission
    // ... (the entire JSX you provided)

    return (
        <React.Fragment>
            {/* Scroll Progress Indicator */}
            <div className="scroll-progress" style={{ width: scrollProgress }} />

            {/* CSAI CODE Section */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="landing-section">
                        <div className="row mobile-reverse">
                            <div className="row align-items-center">
                                {/* Left Content */}
                                <div className="col-lg-6">
                                    <h6 className="subtitle slide-up">
                                        Competitive Programming & Lab Assessment
                                    </h6>
                                    <h1 className="main-title slide-up delay-2">
                                        Challenge {' '}
                                        <span className="gradient-text">Coding Skills</span>
                                        {' '}with{' '}
                                        <span className="brand-text">CSAI CODE</span>
                                    </h1>

                                    <p className="lead-text slide-up delay-4">
                                        Join thousands of developers in competitive programming contests and assessments.
                                        Experience advanced lab evaluation with real-time tracking and anti-plagiarism protection.
                                    </p>

                                    <div className="features-grid">
                                        <div className="feature-item fade-in delay-2">
                                            <div className="feature-icon">
                                                <i className="fas fa-flask"></i>
                                            </div>
                                            <div className="feature-content">
                                                <h5>Advanced Lab Assessment</h5>
                                                <p>Comprehensive evaluation system with automated testing and real-time feedback</p>
                                            </div>
                                        </div>

                                        <div className="feature-item fade-in delay-4">
                                            <div className="feature-icon">
                                                <i className="fas fa-chart-line"></i>
                                            </div>
                                            <div className="feature-content">
                                                <h5>Real-time Tracking</h5>
                                                <p>Monitor student's activities and coding progress with detailed analytics</p>
                                            </div>
                                        </div>

                                        <div className="feature-item fade-in delay-6">
                                            <div className="feature-icon">
                                                <i className="fas fa-shield-alt"></i>
                                            </div>
                                            <div className="feature-content">
                                                <h5>Anti-Plagiarism System</h5>
                                                <p>Advanced code similarity detection and copy-checking mechanisms</p>
                                            </div>
                                        </div>

                                        <div className="feature-item fade-in delay-8">
                                            <div className="feature-icon">
                                                <i className="fas fa-code-branch"></i>
                                            </div>
                                            <div className="feature-content">
                                                <h5>Code Quality Analysis</h5>
                                                <p>Automated code review and quality assessment tools</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cta-buttons slide-up delay-8">
                                        <Link
                                            to={{ pathname: "https://contest.cognistorm.ai" }}
                                            target="_blank"
                                            className="primary-btn"
                                        >
                                            Get Started
                                            <span className="arrow">→</span>
                                        </Link>
                                    </div>
                                </div>

                                {/* Right Content */}
                                <div className="col-lg-6">
                                    <div className="hero-image slide-in-right">
                                        <img src={CSAI_CODE_COVER} alt="CSAI CODE Platform" />
                                        {/* <div className="floating-cards">
                                        <div className="card card-1">
                                            <span>🔍 Plagiarism Detection</span>
                                        </div>
                                        <div className="card card-2">
                                            <span>📊 Real-time Analytics</span>
                                        </div>
                                        <div className="card card-3">
                                            <span>⚡Live Assessment</span>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* COLAS Section */}
            <div className="colas-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="colas-image slide-in-left">
                                <img src={COLAS_COVER} alt="COLAS - Collaborative Lab Assessment" />
                                <div className="floating-cards">
                                    <div className="card card-1">
                                        <span>🔍 Similarity Detection</span>
                                    </div>
                                    <div className="card card-2">
                                        <span>🛡️ Plagiarism Prevention</span>
                                    </div>
                                    <div className="card card-3">
                                        <span>👥 Collaborative Assessment</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="colas-content slide-in-right">
                                <h6 className="subtitle fade-in">Collaborative Lab Assessment</h6>
                                <h2 className="title fade-in delay-2">
                                    Advanced <span className="gradient-text">Code Similarity</span> Detection System
                                </h2>
                                <p className="description fade-in delay-4">
                                    COLAS (Collaborative Lab Assessment) is an intelligent system designed to maintain
                                    academic integrity through advanced code similarity detection and plagiarism prevention.
                                </p>

                                <div className="features-grid">
                                    <div className="feature-item fade-in delay-2">
                                        <div className="feature-icon">
                                            <i className="fas fa-fingerprint"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h5>Pattern Recognition</h5>
                                            <p>Advanced algorithms to detect similar code patterns and structures</p>
                                        </div>
                                    </div>

                                    <div className="feature-item fade-in delay-4">
                                        <div className="feature-icon">
                                            <i className="fas fa-code-branch"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h5>Code Analysis</h5>
                                            <p>Deep analysis of code structure, logic, and implementation</p>
                                        </div>
                                    </div>

                                    <div className="feature-item fade-in delay-6">
                                        <div className="feature-icon">
                                            <i className="fas fa-chart-line"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h5>Similarity Metrics</h5>
                                            <p>Detailed similarity scores and comparison analytics</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="metrics-container fade-in delay-8">
                                    <div className="metric-item">
                                        <div className="metric-value">{'>'}90%</div>
                                        <div className="metric-label">Accuracy Rate</div>
                                    </div>
                                    <div className="metric-item">
                                        <div className="metric-value">Email</div>
                                        <div className="metric-label">Result</div>
                                    </div>
                                    <div className="metric-item">
                                        <div className="metric-value">Any</div>
                                        <div className="metric-label">Supported Languages</div>
                                    </div>
                                </div>

                                <div className="cta-buttons slide-up delay-8">
                                    <Link to="/colas" className="primary-btn">
                                        Get Started
                                        <span className="arrow">→</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* News Summary Section */}
            <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="news-summary-section">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="colas-content">
                                    <h6 className="subtitle">Intelligent Information Distillation</h6>
                                    <h2 className="title">
                                        Introducing <span className="gradient-text">PressWiz</span>
                                    </h2>
                                    <p className="description">
                                        <span style={{ color: '#308C84', fontWeight: 600 }}>
                                            PressWiz
                                        </span>
                                        {' '}is an advanced AI-powered news summarization platform that transforms complex news articles into concise, meaningful insights across multiple domains and sources.
                                    </p>

                                    <div className="features-grid">
                                        {[
                                            {
                                                title: 'Multi-Source Aggregation',
                                                icon: 'fa-globe',
                                                description: 'Collect and analyze news from diverse global sources and perspectives'
                                            },
                                            {
                                                title: 'AI-Powered Summarization',
                                                icon: 'fa-brain',
                                                description: 'Advanced natural language processing to extract key insights'
                                            },
                                            {
                                                title: 'Customized Insights',
                                                icon: 'fa-filter',
                                                description: 'Personalized news summaries tailored to your interests'
                                            }
                                        ].map((feature, index) =>
                                            renderFeatureItem(feature.title, feature.description, feature.icon, index, newsSummaryFeaturesRef)
                                        )}
                                    </div>

                                    <div className="metrics-container">
                                        {[
                                            { value: '100+', label: 'News Sources' },
                                            { value: '1.5 min', label: 'Average Read Time' },
                                            { value: 'AI', label: 'Summarization Tech' }
                                        ].map((metric, index) => (
                                            <div key={metric.label} className="metric-item">
                                                <div className="metric-value">{metric.value}</div>
                                                <div className="metric-label">{metric.label}</div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="cta-buttons">
                                        <Link to="/presswiz" className="primary-btn">
                                            Try PressWiz
                                            <span className="arrow">→</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div ref={newsSummaryImageRef} className="colas-image">
                                    <img src={Press} alt="PressWiz - AI News Summarization" />
                                    <div className="floating-cards">
                                        {/* {['📰 Multi-Source', '🤖 AI-Powered', '⏱️ Quick Insights'].map((card, index) => (
                                            <div
                                                key={card}
                                                ref={(el) => addToRefs(el, newsSummaryCardsRef)}
                                                className={`card card-${index + 1}`}
                                            >
                                                <span>{card}</span>
                                            </div>
                                        ))} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <ScrollToTop scrollClassName="scrollup orange-color" />

            <style jsx>{`
                /* Global Styles & Animations */
                .scroll-progress {
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 3px;
                    background: linear-gradient(90deg, #308C84, #1a5f58);
                    z-index: 1000;
                    transition: width 0.3s ease;
                }

                /* Base Animation Classes */
                .fade-in, .slide-up, .slide-in-right, .slide-in-left {
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
                }

                .fade-in.animate-in {
                    opacity: 1;
                    visibility: visible;
                }

                .slide-up {
                    transform: translateY(30px);
                }

                .slide-up.animate-in {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }

                .slide-in-right {
                    transform: translateX(50px);
                }

                .slide-in-right.animate-in {
                    transform: translateX(0);
                    opacity: 1;
                    visibility: visible;
                }

                .slide-in-left {
                    transform: translateX(-50px);
                }

                .slide-in-left.animate-in {
                    transform: translateX(0);
                    opacity: 1;
                    visibility: visible;
                }

                /* Delay Utilities */
                .delay-2 { transition-delay: 0.2s; }
                .delay-4 { transition-delay: 0.4s; }
                .delay-6 { transition-delay: 0.6s; }
                .delay-8 { transition-delay: 0.8s; }

                /* Section Styles */
                .landing-section, .colas-section {
                    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
                    color: #1a202c;
                    padding: 6rem 0;
                    overflow: hidden;
                }

                .colas-section {
                    background: #f8fafc;
                }

                /* Typography */
                .subtitle {
                    color: #308C84;
                    font-weight: 600;
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }

                .main-title, .title {
                    font-size: 3.5rem;
                    font-weight: 800;
                    line-height: 1.2;
                    margin-bottom: 1.5rem;
                }

                .gradient-text {
                    background: linear-gradient(135deg, #308C84 0%, #1a5f58 100%);
                    background-size: 200% auto;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    animation: shimmer 3s linear infinite;
                }

                .brand-text {
                    font-size: 3.5rem;
                    background: linear-gradient(135deg, #308C84 0%, #1a5f58 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 800;
                }

                .lead-text, .description {
                    font-size: 1.2rem;
                    color: #4a5568;
                    line-height: 1.8;
                    margin-bottom: 2.5rem;
                }

                /* Features Grid */
                .features-grid {
                    display: grid;
                    gap: 2rem;
                    margin-bottom: 3rem;
                }

                .feature-item {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    transition: all 0.3s ease;
                    position: relative;
                }

                .feature-item:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 10px 20px rgba(48, 140, 132, 0.1);
                }

                .feature-icon {
                    background: rgba(48, 140, 132, 0.1);
                    padding: 1rem;
                    border-radius: 50%;
                    color: #308C84;
                    font-size: 1.5rem;
                    transition: all 0.3s ease;
                }

                .feature-item:hover .feature-icon {
                    background: #308C84;
                    color: white;
                    transform: rotate(360deg);
                }

                .feature-content h5 {
                    color: #1a202c;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }

                .feature-content p {
                    color: #4a5568;
                    margin: 0;
                }

                /* Buttons */
                .cta-buttons {
                    display: flex;
                    gap: 1rem;
                    margin-top: 2rem;
                }

                .primary-btn, .secondary-btn {
                    padding: 1rem 2rem;
                    border-radius: 12px;
                    font-weight: 600;
                    display: inline-flex;
                    align-items: center;
                    gap: 0.5rem;
                    transition: all 0.3s ease;
                    position: relative;
                    overflow: hidden;
                }

                .primary-btn {
                    background: linear-gradient(135deg, #308C84 0%, #1a5f58 100%);
                    color: white;
                    box-shadow: 0 4px 20px rgba(48, 140, 132, 0.3);
                }

                .secondary-btn {
                    border: 2px solid #308C84;
                    color: #308C84;
                }

                .primary-btn:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 6px 25px rgba(48, 140, 132, 0.4);
                }

                .secondary-btn:hover {
                    background: rgba(48, 140, 132, 0.1);
                }

                .arrow {
                    transition: transform 0.3s ease;
                }

                .primary-btn:hover .arrow,
                .secondary-btn:hover .arrow {
                    transform: translateX(5px);
                }

                /* Images and Cards */
                .hero-image, .colas-image {
                    position: relative;
                    transition: transform 0.3s ease-out;
                }

                .hero-image img, .colas-image img {
                    width: 100%;
                    border-radius: 20px;
                    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
                    transition: all 0.5s ease;
                }

                .hero-image:hover img, .colas-image:hover img {
                    transform: scale(1.02) rotate(1deg);
                    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
                }

                .floating-cards {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                .card {
                    position: absolute;
                    background: white;
                    padding: 1rem 1.5rem;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
                    transition: all 0.3s ease;
                }

                .card:hover {
                    transform: translateY(-10px) rotate(2deg);
                    box-shadow: 0 15px 30px rgba(48, 140, 132, 0.2);
                }

                .card-1 {
                    top: 10%;
                    left: -10%;
                    animation: float 6s ease-in-out infinite;
                }

                .card-2 {
                    top: 40%;
                    right: -10%;
                    animation: float 6s ease-in-out infinite 2s;
                }

                .card-3 {
                    bottom: 10%;
                    left: 10%;
                    animation: float 6s ease-in-out infinite 4s;
                }

                /* Metrics */
                .metrics-container {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 3rem;
                }

                .metric-item {
                    text-align: center;
                }

                .metric-value {
                    font-size: 2rem;
                    font-weight: 800;
                    background: linear-gradient(135deg, #308C84 0%, #1a5f58 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 0.5rem;
                }

                .metric-label {
                    color: #4a5568;
                    font-size: 0.9rem;
                }

                /* Animations */
                @keyframes float {
                    0%, 100% { transform: translateY(0); }
                    50% { transform: translateY(-20px); }
                }

                @keyframes shimmer {
                    to { background-position: 200% center; }
                }

                /* Responsive Design */
                @media (max-width: 768px) {
                    .main-title, .brand-text {
                        font-size: 2.5rem;
                    }

                    .metrics-container {
                        flex-direction: column;
                        gap: 2rem;
                    }

                    .cta-buttons {
                        flex-direction: column;
                    }

                    .floating-cards {
                        display: none;
                    }

                    .slide-in-right,
                    .slide-in-left {
                        transform: translateX(0);
                    }

                    .slide-up {
                        transform: translateY(20px);
                    }

                    .delay-2,
                    .delay-4,
                    .delay-6,
                    .delay-8 {
                        transition-delay: 0.1s;
                    }
                }
                /* Base styles */
                .landing-section,
                .colas-section,
                .news-summary-section {
                    width: 100%;
                }

                .row {
                display: flex;
                    flex-wrap: wrap;
                }

                /* Desktop styles (default) */
                .col-lg-6 {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                /* Mobile styles */
                @media (max-width: 991px) {
                    .landing-section .row,
                    .news-summary-section .row {
                      flex-direction: column-reverse;
                    }
                    
                    /* COLAS section stays in original order */
                    .colas-section .row {
                      flex-direction: column;
                    }
                    .row {
                        flex-direction: column;
                    }
                    
                    .col-lg-6 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    
                    /* Reverse order for CSAI CODE section on mobile */
                    .landing-section .row {
                        flex-direction: column-reverse;
                    }
                    
                    /* Adjust spacing */
                    .col-lg-6:not(:last-child) {
                        margin-bottom: 2rem;
                    }
                    
                    /* Image container adjustments */
                    .hero-image,
                    .colas-image {
                        margin: 0 auto;
                        max-width: 80%;
                        padding: 1rem;
                    }
                    
                    /* Text alignment for mobile */
                    .subtitle,
                    .main-title,
                    .lead-text,
                    .description {
                        text-align: center;
                    }
                    
                    /* Center CTA buttons */
                    .cta-buttons {
                        display: flex;
                        justify-content: center;
                    }
                    
                    /* Features grid adjustment */
                    .features-grid {
                        grid-template-columns: 1fr;
                        gap: 1.5rem;
                        padding: 1rem;
                    }
                    
                    /* Metrics container adjustment */
                    .metrics-container {
                        justify-content: center;
                        flex-wrap: wrap;
                        gap: 1rem;
                    }
                }
            `}</style>
        </React.Fragment>
    );
};

export default ServiceDetailsContent;