
import React from 'react';
import { Helmet } from 'react-helmet';
import BlogMain from './BlogMain';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
// import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

// Image
// import favIcon from '../../assets/img/fav-orange.png';
import icon from '../../assets/img/logo/ai.png';
// import Logo from '../../assets/img/logo/dark-logo.png';
// import footerLogo from '../../assets/img/logo/lite-logo.png';
// import Logo2 from '../../assets/img/logo/logo2.png';
import logo from '../../assets/img/logo/csai.png';

// import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import banner from '../../assets/img/banner/cail.png';

const Blog = () => {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={icon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='blog'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Blog"
                pageName="Blog"
                breadcrumbsImg={banner}
            />
            {/* breadcrumb-area-End */}

            {/* Blog Main */}
            <BlogMain />
            {/* Blog Main End */}

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-style mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}


export default Blog;

