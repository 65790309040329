// dateUtils.js
export const formatBanglaDate = (dateString) => {
    const date = new Date(dateString);
    const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
    };
    
    // Use Intl for localization
    return date.toLocaleDateString('bn-BD', options);
};