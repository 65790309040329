import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer/Footer';

import SiteBreadcrumb from './Breadcumb';

// Image and Logos
import logo from '../../assets/img/logo/csai.png';
import icon from '../../assets/img/logo/ai.png';

import NewspaperComponent from './NewspaperComponent';
import CompactNewspaperComponent from './CompactNewspaperComponent';

const Newspaper = () => {
    const [mode, setMode] = useState('normal');

    const handleViewModeChange = (mode) => {
        setMode(mode);
    }

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={icon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='solutions'
                headerNormalLogo={logo}
                headerStickyLogo={logo}
                CanvasLogo={logo}
                mobileNormalLogo={logo}
            />
            <SiteBreadcrumb
                titleClass="page-title"
                pageName="Newspaper"
                onViewModeChange={handleViewModeChange}
            />

            {mode === 'normal' && <NewspaperComponent />}
            {mode === 'compact' && <CompactNewspaperComponent />}
            
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={logo}
            />
        </React.Fragment>

    );
}


export default Newspaper;