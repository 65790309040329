import './NewspaperComponent.css';
const Pagination = ({
    currentPage,
    pagination,
    inputPage,
    isLoading,
    onPageChange,
    onInputChange,
    onDirectPageChange,
}) => (
    <div className="pagination">
        <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={!pagination.has_prev || isLoading}
            className={`pagination-btn ${isLoading ? 'loading' : ''}`}
        >
            {isLoading ? 'Loading...' : '← আগের পাতা'}
        </button>

        <div className="page-input-container">
            <input
                type="number"
                className="page-input"
                value={inputPage}
                onChange={(e) => onInputChange(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onDirectPageChange();
                    }
                }}
                min="1"
                max={pagination.total_pages}
            />
            <button
                className="go-to-page-btn"
                onClick={onDirectPageChange}
                disabled={isLoading}
            >
                যান
            </button>
        </div>

        <span className="page-info">
            / {pagination.total_pages}
        </span>

        <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={!pagination.has_next || isLoading}
            className={`pagination-btn ${isLoading ? 'loading' : ''}`}
        >
            {isLoading ? 'Loading...' : 'পরের পাতা →'}
        </button>
    </div>
);

export default Pagination;